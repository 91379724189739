import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/sdk2'
  },
  {
    path: '/sdk',
    name: 'SDK',
    component: () => import('../views/SDK.vue')
  },
  {
    path: '/sdk2',
    name: 'SDKv2',
    component: () => import('../views/SDKv2.vue')
  },
  {
    path: '/lps',
    name: 'LPS',
    component: () => import('../views/LPS.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
