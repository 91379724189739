<template>
  <v-app>
    <v-main>
<!--      <v-row>-->
<!--        <v-col cols="6">-->
<!--          <router-link to="/sdk">-->
<!--            <v-btn block color="green">SDK</v-btn>-->
<!--          </router-link>-->
<!--        </v-col>-->
<!--        <v-col cols="6">-->
<!--          <router-link to="/lps">-->
<!--            <v-btn block color="cyan">LPS2</v-btn>-->
<!--          </router-link>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <router-view/>
      
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.lsn {
  letter-spacing: normal;
}
::-webkit-scrollbar {
  width: 0 !important;
}
</style>