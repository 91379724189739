import Vue from 'vue'
import Vuex from 'vuex'

import runner from '../connection'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseQueryParams: null,
    messages: [],
    online: false,
    lastMessageUuid: "",
    appRunUuid: "",
  },
  mutations: {
    setAppRunUuid: (state, payload) => {
      state.appRunUuid = payload;
    },
    setBaseQueryParams: (state, payload) => {
      state.baseQueryParams = payload;
    },
    addMessage: (state, payload) => {
      state.messages.push(payload)
    },
    setOnlineState: (state, payload) => {
      state.online = payload;
    },
    setLastMessageUuid: (state, payload) => {
      state.lastMessageUuid = payload;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    sendMessageToRunner({ commit }, { message, answerExpected }) {
      let uuid = runner.sendMessageToRunner(message);
      commit("setLastMessageUuid", uuid);
      if (!answerExpected) {
        return Promise.resolve();
      }

    },
    onRunnerMessageReceived({ commit }, message) {
      if (!message.requestUuid) return;
      commit("addMessage", message);
    },
  },
  getters: {
    baseQueryParams(state) {
      return state.baseQueryParams
    }
  }
})



  
