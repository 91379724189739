import { HubConnectionBuilder } from '@microsoft/signalr';
import { v4 } from 'uuid';

let connection;
let store;
// const allowedMessages = [
//   'System.Error',
//   'System.GetStateResult',
//   'System.InstallDeviceResult',
// ];

export default {
  connection,
  initializeRunnerConnection(config, storeInstance) {
    store = storeInstance;
    connection = new HubConnectionBuilder()
      .withUrl(config.endpoint)
      .withAutomaticReconnect()
      .build();
    return connection.start().then(() => {
      store.commit('setOnlineState', true);
      connection.on('messaging', this.onRunnerMessageReceived);
      return store.dispatch('sendMessageToRunner', {
        message: {
          action: 'System.GetState',
        },
      }).then(() => {

      }).catch(() => {
        store.commit('setOnlineState', false)
      })
    });
  },
  onRunnerMessageReceived(message) {
    // eslint-disable-next-line
    console.info('Message received', message);
    console.log(typeof message);
    if (!message.action) {
      // eslint-disable-next-line
      console.error('Invalid message format, missing action');
      return;
    }

    // if (!allowedMessages.indexOf(message.action) < 0) {
    //   // eslint-disable-next-line
    //   console.error(`Invalid action - ${message.action} is not supported`);
    //   return;
    // }

    store.dispatch('onRunnerMessageReceived', message);
  },
  sendMessageToRunner(message) {
    console.log(message);
    const messageUuid = v4();

    const payload = {
      uuid: messageUuid,
      ...message,
    };
    if (payload.data) payload.data = JSON.stringify(payload.data);
    connection.invoke('messaging', payload);
    return messageUuid;
  },
};
